import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import FAQ from '../components/faq/faq';

import faqs from '../components/faq/delivery-faqs';
import ImageBackground from "../components/imagebackground";
import settings from "../../settings";

const banner =`${settings.IMAGES_BASE_URL}/v2/images/faq-banner.jpg`;

const DeliveryFAQPage = () => {
  return (
    <Layout>
      <SEO title="BMW Performance Driving School | Delivery Program FAQ" description="Frequently asked questions for new BMW owners who qualify for the BMW Performance Center Delivery Program and are ready to fully experience BMW culture!" />
      <div className="faq-page deliveryfaq">
        <ImageBackground
          imageDesktop={banner}
          imageMobile={banner}
          imageAlt="press"
          imageClassName="banner"
          topLeftChildren={
            <div>
              <div className="theme__text">
                <div>
                  <h3>DELIVERY FREQUENTLY</h3>
                  <h3>ASKED QUESTIONS</h3>
                </div>
              </div>
              <div className="subheader">Listed below are some common questions regarding the<br/> new-vehicle delivery program offered by the BMW<br/>Performance Center. For additional help, email<br/> <a className="phone" href="mailto:PerformanceCenterDelivery@bmwmc.com">PerformanceCenterDelivery@bmwmc.com</a>.</div>
            </div>
          }
          hideArrow
        />
        <section className="faq">
          <div className="container">
            <FAQ faqs={faqs} />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default DeliveryFAQPage;
